
<template>
    <div class="overlay w-all p-2 lg:p-4">
        <EventTile>
            <slot />
        </EventTile>
    </div>
</template>
<style scoped>
.overlay {
    background-color: #E5E5E5;
}

.calendar-btn {
    background: red;
}

::v-deep(.heading) {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}
</style>
